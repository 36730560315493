import React, { useEffect, useState } from "react";
import { Card } from "@aidron/aidron-ds";
import { getOpportunitiesCount } from "../../../services/OpportunityService.js";

import * as S from "./OpportunityCounter.style.js";

import CardContent from "./CardContent/CardContent.jsx";
import LoadingProgress from "../../../components/LoadingProgress/LoadingProgress.jsx";

export default function OpportunityCounter({ typeOptions, forecast, opportunities }) {
  const [counter, setCounter] = useState({});

  const status = ["active", "reactivated"];

  const opportunitiesInCheckout = opportunities.filter(opportunity => opportunity.status === "checkout");

  const checkoutSum = opportunitiesInCheckout.reduce((acc, curr) => {
    if (curr.status === "checkout") {
      return Number(acc) + Number(curr.value);
    } else return acc;
  }, 0);
  const checkoutSumToDisplay = checkoutSum > 0 ? checkoutSum : 0;

  const checkoutCount = opportunitiesInCheckout.length;
  const checkoutCountToDisplay = checkoutCount > 0 ? checkoutCount : "0";

  useEffect(() => {
    fetchData();
  }, [opportunities]);

  async function fetchData() {
    if (forecast.forecastId) {
      const token = localStorage.getItem("token");
      const result = await getOpportunitiesCount(token, forecast.forecastId, status);
      setCounter(result);
    }
  }

  function getTypeData(type) {
    return counter.counter?.find(obj => obj.type === type.value);
  }

  return counter.count ? (
    <S.Container>
      <Card>
        <CardContent
          label="Total de Oportunidades"
          number={counter.totalValue}
          legendNumber={counter.count}
          numberColor="orange"
        />
      </Card>
      <LoadingProgress condition={typeOptions.length}>
        <Card>
          <S.CardWrapper>
            {typeOptions.map(type => (
              <CardContent
                label={type.displayName}
                number={getTypeData(type)?.totalValue}
                legendNumber={getTypeData(type)?.count}
                numberColor="orange"
                key={type.value}
              />
            ))}
          </S.CardWrapper>
        </Card>
      </LoadingProgress>
      <Card>
        <CardContent
          label="Em Checkout"
          number={Math.floor(checkoutSumToDisplay)}
          legendNumber={checkoutCountToDisplay}
          numberColor="orangeGrey"
          linkTo={checkoutCount > 0 ? "/acoes/checkout" : ""}
        />
      </Card>
    </S.Container>
  ) : (
    <></>
  );
}
